import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Helmet from "react-helmet";
import ReactMarkdown from "react-markdown";

import Layout from "../components/Layout";
import Map from "../components/Map";
import CustomLink from "../components/CustomLink";
import { SlideShow } from "../components/SlideShow/SlideShow";

import "../styles/home.scss";
import CustomImage from "../components/Image";

export const HomePageTemplate = ({ home }) => {
  return (
    <>
      <section className="header">
        <SlideShow height="unset" slides={home.gallery} autoPlay slideDuration={800} />

        <div className="header-container container">
          <h3 className="header-tagline">
            <span className="header-taglinePart">{home.title}</span>
          </h3>
        </div>
      </section>
      <section className="intro section">
        <div className="intro-container container">
          <h1 className="intro-title">{home.introHeader}</h1>
          <ReactMarkdown className="intro-description" source={home.intro} />
          {home.introGallery ? (
            <ul className="intro-gallery">
              {home.introGallery.map((galleryImage, index) => (
                <li key={index} className="intro-item">
                  <CustomImage gatsbyImage={galleryImage.image.childImageSharp} src={galleryImage.image} alt={galleryImage.imageAlt} />
                </li>
              ))}
            </ul>
          ) : null}
        </div>
      </section>
      <section className="ctaBlock">
        <CustomLink
          linkType={home.callToActions.firstCTA.linkType}
          linkURL={home.callToActions.firstCTA.linkURL}
          className="ctaBlock-pattern  ctaBlock-pattern--first"
        >
          <div className="ctaBlock-cta">
            <span className="ctaBlock-ctaHeading">{home.callToActions.firstCTA.heading}</span>
            <p className="ctaBlock-ctaDescription">{home.callToActions.firstCTA.subHeading}</p>
          </div>
        </CustomLink>
        <CustomLink
          linkType={home.callToActions.secondCTA.linkType}
          linkURL={home.callToActions.secondCTA.linkURL}
          className="ctaBlock-pattern  ctaBlock-pattern--second"
        >
          <div className="ctaBlock-cta">
            <span className="ctaBlock-ctaHeading">{home.callToActions.secondCTA.heading}</span>
            <p className="ctaBlock-ctaDescription">{home.callToActions.secondCTA.subHeading}</p>
          </div>
        </CustomLink>
      </section>
      <section className="map-section">
        <div className="container">
          <p className="home-mapNote">{home.mapsNote}</p>
          <div className="home-mapWrapper">
            <Map
              isMarkerShown
              googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyB9OOZoACYaUM7kT0Y-YKtICqaOfKNP8QI&v=3.exp&libraries=geometry,drawing,places"
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={<div style={{ height: `100%` }} />}
              mapElement={<div style={{ height: `100%` }} />}
              link={home.location.mapsLink}
              latitude={home.location.mapsLatitude}
              longitude={home.location.mapsLongitude}
            />
          </div>
        </div>
      </section>
    </>
  );
};

class HomePage extends React.Component {
  render() {
    const { data } = this.props;
    const {
      data: { footerData, navbarData },
    } = this.props;
    const { frontmatter: home } = data.homePageData.edges[0].node;
    const {
      seo: { title: seoTitle, description: seoDescription, browserTitle },
    } = home;

    return (
      <Layout footerData={footerData} navbarData={navbarData}>
        <Helmet>
          <meta name="title" content={seoTitle} />
          <meta name="description" content={seoDescription} />
          <title>{browserTitle}</title>
        </Helmet>
        <HomePageTemplate home={home} />
      </Layout>
    );
  }
}

HomePage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export default HomePage;

export const pageQuery = graphql`
  query HomePageQuery {
    ...LayoutFragment
    homePageData: allMarkdownRemark(filter: { frontmatter: { templateKey: { eq: "home-page" } } }) {
      edges {
        node {
          html
          frontmatter {
            title
            gallery {
              image {
                childImageSharp {
                  fluid(maxWidth: 1400) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
              imageAlt
              name
            }
            introHeader
            intro
            introGallery {
              image {
                childImageSharp {
                  fluid(maxWidth: 700) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
              imageAlt
              name
            }
            mapsNote
            location {
              mapsLatitude
              mapsLink
              mapsLongitude
              name
            }
            callToActions {
              firstCTA {
                heading
                subHeading
                linkType
                linkURL
              }
              secondCTA {
                heading
                subHeading
                linkType
                linkURL
              }
            }
            seo {
              browserTitle
              title
              description
            }
          }
        }
      }
    }
  }
`;

import React from 'react';
import ImageGallery from 'react-image-gallery';
import CustomImage from '../Image';
import './styles.scss';

const SlideShowItem = ({ width, height, item }) => {
  if (!item.gatsbyImage) {
    return null;
  }
  return (
    <CustomImage
      gatsbyImage={item.gatsbyImage.childImageSharp}
      src={item.thumbnail}
      otherProps={{
        className: 'slideshow-item',
        key: item.src,
        style: {
          width,
          height,
        },
      }}
    />
  );
};

export const SlideShow = props => {
  if (!props.slides) return null;

  const {
    slides,
    height = '500px',
    width = '100%',
    showThumbnails = false,
    showArrows = false,
    showFullscreenButton = false,
    autoPlay = false,
    slideInterval = 5000,
    slideDuration = 450,
  } = props;

  const images = slides.map(i => {
    return {
      original: i.image.id,
      thumbnail: i.image.childImageSharp.fluid.src,
      alt: i.alt,
      gatsbyImage: i.image,
    };
  });

  return (
    <div className="slideshow">
      <ImageGallery
        items={images}
        showThumbnails={showThumbnails}
        showPlayButton={false}
        showNav={showArrows}
        showFullscreenButton={showFullscreenButton}
        autoPlay={autoPlay}
        slideInterval={slideInterval}
        slideDuration={slideDuration}
        renderItem={item => (
          <SlideShowItem width={width} height={height} item={item} />
        )}
      />
    </div>
  );
};
